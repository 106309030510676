import { ProgramAdmissionPage } from './admin/front-desk/ProgramAdmissionManagerPage';
import React from 'react';

/**
 * This file is used to lazy load all of the pages in the application.
 * It's centralized to avoid redefining the same page, causing a remount.
 */

export const ComingSoonPage = React.lazy(() => import('./ComingSoonPage'));
export const HomePage = React.lazy(() => import('./HomePage'));
export const ResetPasswordPage = React.lazy(
  () => import('./ResetPasswordPage')
);
export const ForgotPasswordPage = React.lazy(
  () => import('./ForgotPasswordPage')
);

export const admin = {
  HomePage: React.lazy(() => import('./admin/HomePage')),
  services: {
    AllergyEditPage: React.lazy(
      () => import('./admin/services/AllergyEditPage')
    ),
    AllergyManagerPage: React.lazy(
      () => import('./admin/services/AllergyManagerPage')
    ),
    AllergyNewPage: React.lazy(() => import('./admin/services/AllergyNewPage')),
    FoodProgramEditPage: React.lazy(
      () => import('./admin/services/FoodProgramEditPage')
    ),
    FoodProgramManagerPage: React.lazy(
      () => import('./admin/services/FoodProgramManagerPage')
    ),
    FoodProgramNewPage: React.lazy(
      () => import('./admin/services/FoodProgramNewPage')
    ),
    ProgramDiscountEditPage: React.lazy(
      () => import('./admin/services/ProgramDiscountEditPage')
    ),
    ProgramDiscountManagerPage: React.lazy(
      () => import('./admin/services/ProgramDiscountManagerPage')
    ),
    ProgramDiscountNewPage: React.lazy(
      () => import('./admin/services/ProgramDiscountNewPage')
    ),
    FineFeeCouponEditPage: React.lazy(
      () => import('./admin/services/FineFeeCouponEditPage')
    ),
    FineFeeCouponManagerPage: React.lazy(
      () => import('./admin/services/FineFeeCouponManagerPage')
    ),
    FineFeeCouponNewPage: React.lazy(
      () => import('./admin/services/FineFeeCouponNewPage')
    ),
    AssigningFeesPage: React.lazy(
      () => import('./admin/services/AssigningFeesPage')
    ),
    PublicPrivateSchoolEditPage: React.lazy(
      () => import('./admin/services/PublicPrivateSchoolEditPage')
    ),
    PublicPrivateSchoolManagerPage: React.lazy(
      () => import('./admin/services/PublicPrivateSchoolManagerPage')
    ),
    PublicPrivateSchoolNewPage: React.lazy(
      () => import('./admin/services/PublicPrivateSchoolNewPage')
    ),
    LedgerItemCategoryEditPage: React.lazy(
      () => import('./admin/services/LedgerItemCategoryEditPage')
    ),
    LedgerItemCategoryManagerPage: React.lazy(
      () => import('./admin/services/LedgerItemCategoryManagerPage')
    ),
    LedgerItemCategoryNewPage: React.lazy(
      () => import('./admin/services/LedgerItemCategoryNewPage')
    ),
    MedicalDocumentationEditPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationEditPage')
    ),
    MedicalDocumentationManagerPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationManagerPage')
    ),
    MedicalDocumentationNewPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationNewPage')
    ),
    MedicalDocumentationTypeEditPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationTypeEditPage')
    ),
    MedicalDocumentationTypeManagerPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationTypeManagerPage')
    ),
    MedicalDocumentationTypeNewPage: React.lazy(
      () => import('./admin/services/MedicalDocumentationTypeNewPage')
    ),
    AccountStatusEditPage: React.lazy(
      () => import('./admin/services/AccountStatusEditPage')
    ),
    AccountStatusManagerPage: React.lazy(
      () => import('./admin/services/AccountStatusManagerPage')
    ),
    AccountStatusNewPage: React.lazy(
      () => import('./admin/services/AccountStatusNewPage')
    ),
    AuthorizeKeyEditPage: React.lazy(
      () => import('./admin/services/AuthorizeKeyEditPage')
    ),
    AuthorizeKeyManagerPage: React.lazy(
      () => import('./admin/services/AuthorizeKeyManagerPage')
    ),
    AuthorizeKeyNewPage: React.lazy(
      () => import('./admin/services/AuthorizeKeyNewPage')
    ),
    ParentChecklistEditPage: React.lazy(
      () => import('./admin/services/ParentChecklistEditPage')
    ),
    ParentChecklistManagerPage: React.lazy(
      () => import('./admin/services/ParentChecklistManagerPage')
    ),
    ParentChecklistNewPage: React.lazy(
      () => import('./admin/services/ParentChecklistNewPage')
    ),
    ProgramEditPage: React.lazy(
      () => import('./admin/services/ProgramEditPage')
    ),
    ProgramManagerPage: React.lazy(
      () => import('./admin/services/ProgramManagerPage')
    ),
    ProgramNewPage: React.lazy(() => import('./admin/services/ProgramNewPage')),
    ProgramNameEditPage: React.lazy(
      () => import('./admin/services/ProgramNameEditPage')
    ),
    ProgramNameManagerPage: React.lazy(
      () => import('./admin/services/ProgramNameManagerPage')
    ),
    ProgramNameNewPage: React.lazy(
      () => import('./admin/services/ProgramNameNewPage')
    ),
    ProgramRegistrationManagerPage: React.lazy(
      () => import('./admin/services/ProgramRegistrationManagerPage')
    ),
    ProgramTypeEditPage: React.lazy(
      () => import('./admin/services/ProgramTypeEditPage')
    ),
    ProgramTypeManagerPage: React.lazy(
      () => import('./admin/services/ProgramTypeManagerPage')
    ),
    ProgramTypeNewPage: React.lazy(
      () => import('./admin/services/ProgramTypeNewPage')
    ),
    RegionEditPage: React.lazy(() => import('./admin/services/RegionEditPage')),
    RegionManagerPage: React.lazy(
      () => import('./admin/services/RegionManagerPage')
    ),
    RegionNewPage: React.lazy(() => import('./admin/services/RegionNewPage')),
    RoomEditPage: React.lazy(() => import('./admin/services/RoomEditPage')),
    RoomManagerPage: React.lazy(
      () => import('./admin/services/RoomManagerPage')
    ),
    RoomNewPage: React.lazy(() => import('./admin/services/RoomNewPage')),
    SamplesManagerPage: React.lazy(
      () => import('./admin/services/SamplesManagerPage')
    ),
    SchoolEditPage: React.lazy(() => import('./admin/services/SchoolEditPage')),
    SchoolManagerPage: React.lazy(
      () => import('./admin/services/SchoolManagerPage')
    ),
    SchoolNewPage: React.lazy(() => import('./admin/services/SchoolNewPage')),
    SettingManagerPage: React.lazy(
      () => import('./admin/services/SettingManagerPage')
    ),
    SettingEditPage: React.lazy(
      () => import('./admin/services/SettingEditPage')
    ),
    //
    TeacherCountLogManagerPage: React.lazy(
      () => import('./admin/services/TeacherCountLogManagerPage')
    ),
    TeacherCountLogNewPage: React.lazy(
      () => import('./admin/services/TeacherCountLogNewPage')
    ),
    TeacherCountLogEditPage: React.lazy(
      () => import('./admin/services/TeacherCountLogEditPage')
    ),
    //
    TransitionLogManagerPage: React.lazy(
      () => import('./admin/services/TransitionLogManagerPage')
    ),
    TransitionLogNewPage: React.lazy(
      () => import('./admin/services/TransitionLogNewPage')
    ),
    TransitionLogEditPage: React.lazy(
      () => import('./admin/services/TransitionLogEditPage')
    ),
    UserEditPage: React.lazy(() => import('./admin/services/UserEditPage')),
    UserManagerPage: React.lazy(
      () => import('./admin/services/UserManagerPage')
    ),
    UserNewPage: React.lazy(() => import('./admin/services/UserNewPage')),
    WaiverManagerPage: React.lazy(
      () => import('./admin/services/WaiverManagerPage')
    ),
    WaiverEditPage: React.lazy(() => import('./admin/services/WaiverEditPage')),
    SchoolImporterPage: React.lazy(
      () => import('./admin/services/SchoolImporterPage')
    ),
    ACHProcessReturnsPage: React.lazy(
      () => import('./admin/services/ACHProcessReturnsPage')
    ),
  },
  programCalendar: {
    ClassEditPage: React.lazy(() => import('./admin/services/ClassEditPage')),
    ClassManagerPage: React.lazy(
      () => import('./admin/services/ClassManagerPage')
    ),
    ClassNewPage: React.lazy(() => import('./admin/services/ClassNewPage')),
    ClassInfoEditPage: React.lazy(
      () => import('./admin/services/ClassInfoEditPage')
    ),
    ClassInfoNewPage: React.lazy(
      () => import('./admin/services/ClassInfoNewPage')
    ),
    ClassRegistrationManagerPage: React.lazy(
      () => import('./admin/services/ClassRegistrationManagerPage')
    ),
    SeasonEditPage: React.lazy(() => import('./admin/services/SeasonEditPage')),
    SeasonManagerPage: React.lazy(
      () => import('./admin/services/SeasonManagerPage')
    ),
    SeasonNewPage: React.lazy(() => import('./admin/services/SeasonNewPage')),
    SubjectEditPage: React.lazy(
      () => import('./admin/services/SubjectEditPage')
    ),
    SubjectManagerPage: React.lazy(
      () => import('./admin/services/SubjectManagerPage')
    ),
    SubjectNewPage: React.lazy(() => import('./admin/services/SubjectNewPage')),
    TeacherPermissionManagerPage: React.lazy(
      () => import('./admin/services/TeacherPermissionManagerPage')
    ),
    ClassAttendancePage: React.lazy(
      () => import('./admin/services/ClassAttendancePage')
    ),
  },
  agencyAssistance: {
    AgencyEditPage: React.lazy(
      () => import('./admin/agency-assistance/AgencyEditPage')
    ),
    AgencyManagerPage: React.lazy(
      () => import('./admin/agency-assistance/AgencyManagerPage')
    ),
    AgencyNewPage: React.lazy(
      () => import('./admin/agency-assistance/AgencyNewPage')
    ),
    AgencyLogManagerPage: React.lazy(
      () => import('./admin/agency-assistance/AgencyLogManagerPage')
    ),
  },
  frontDesk: {
    MembershipGAPage: React.lazy(
      () => import('./admin/front-desk/MembershipGAPage')
    ),
    MembershipGANewPage: React.lazy(
      () => import('./admin/front-desk/MembershipGANewPage')
    ),
    MembershipGAEditPage: React.lazy(
      () => import('./admin/front-desk/MembershipGAEditPage')
    ),
    ProgramAdmissionManagerPage: React.lazy(
      () => import('./admin/front-desk/ProgramAdmissionManagerPage')
    ),
    ProgramAdmissionNewPage: React.lazy(
      () => import('./admin/front-desk/ProgramAdmissionNewPage')
    ),
    ProgramAdmissionEditPage: React.lazy(
      () => import('./admin/front-desk/ProgramAdmissionEditPage')
    ),
  },
  reports: {
    Report: React.lazy(() => import('./admin/reports/ReportPage')),
    ReportsByCategoryPage: React.lazy(
      () => import('./admin/reports/ReportsByCategoryPage')
    ),
  },
};

export const dev = {
  ComponentExamplesPage: React.lazy(
    () => import('./dev/ComponentExamplesPage')
  ),
  ThemeExamplesPage: React.lazy(() => import('./dev/ThemeExamplesPage')),
  FormInputExamplesPage: React.lazy(
    () => import('./dev/FormInputExamplesPage')
  ),
  HomePage: React.lazy(() => import('./dev/HomePage')),
  MinimalDataGridExamplePage: React.lazy(
    () => import('./dev/MinimalDataGridExamplePage')
  ),
  MinimalFormExamplePage: React.lazy(
    () => import('./dev/MinimalFormExamplePage')
  ),
  WumpusEditPage: React.lazy(() => import('./dev/WumpusEditPage')),
  WumpusManagerPage: React.lazy(() => import('./dev/WumpusManagerPage')),
  WumpusNewPage: React.lazy(() => import('./dev/WumpusNewPage')),
  PDFExamplePage: React.lazy(() => import('./dev/PDFExamplePage')),
};

export const frontDesk = {
  QuickCheckIn: React.lazy(() => import('./front-desk/QuickCheckInPage')),
  Bus: React.lazy(() => import('./front-desk/BusPage')),
  CheckOut: React.lazy(() => import('./front-desk/CheckOutPage')),
  ProgramCheckIn: React.lazy(() => import('./front-desk/ProgramCheckInPage')),
  MemberGuestGroup: React.lazy(
    () => import('./front-desk/MemberGuestGroupPage')
  ),
  MemberGuestGroupCompleted: React.lazy(
    () => import('./front-desk/MemberGuestGroupCompletedPage')
  ),
  TakePayment: React.lazy(() => import('./front-desk/TakePaymentPage')),
  TakePaymentCompleted: React.lazy(
    () => import('./front-desk/TakePaymentCompletedPage')
  ),
  WhosHere: React.lazy(() => import('./front-desk/WhosHerePage')),
  WhosHereByProgramType: React.lazy(
    () => import('./front-desk/WhosHereByProgramTypePage')
  ),
  WhosHereByProgramGroup: React.lazy(
    () => import('./front-desk/WhosHereByProgramGroupPage')
  ),
  WhosHerePrintout: React.lazy(
    () => import('./front-desk/WhosHerePrintoutPage')
  ),
};

/**
 * Note: Staff Portal originally was its own App Area, but it was merged into the admin.
 * We opted to leave the page files where they are and only update routing & menus.
 */
export const staff = {
  ChildEditPage: React.lazy(() => import('./staff/ChildEditPage')),
  ChildNewPage: React.lazy(() => import('./staff/ChildNewPage')),
  ClassEnrollmentNewPage: React.lazy(
    () => import('./staff/ClassEnrollmentNewPage')
  ),
  ClassEnrollmentCompletePage: React.lazy(
    () => import('./staff/ClassEnrollmentCompletePage')
  ),
  ClassEnrollmentCompletedPage: React.lazy(
    () => import('./staff/ClassEnrollmentCompletedPage')
  ),
  ClassInfoPage: React.lazy(() => import('./staff/ClassInfoPage')),
  ClassSchedulePage: React.lazy(() => import('./staff/ClassSchedulePage')),
  CalendarPage: React.lazy(() => import('./staff/CalendarPage')),
  UpcomingEventsPage: React.lazy(() => import('./staff/UpcomingEventsPage')),
  EventManagerPage: React.lazy(() => import('./staff/EventManagerPage')),
  EventManagerNewPage: React.lazy(() => import('./staff/EventManagerNewPage')),
  EventManagerRegistrationsPage: React.lazy(
    () => import('./staff/EventManagerRegistrationsPage')
  ),
  EventManagerRecurringEditPage: React.lazy(
    () => import('./staff/EventManagerRecurringEditPage')
  ),
  EventManagerRecurringNewPage: React.lazy(
    () => import('./staff/EventManagerRecurringNewPage')
  ),
  EventRecurringManagerPage: React.lazy(
    () => import('./staff/EventRecurringManagerPage')
  ),
  EventRegistrationNewPage: React.lazy(
    () => import('./staff/EventRegistrationNewPage')
  ),
  EventRegistrationEditPage: React.lazy(
    () => import('./staff/EventRegistrationEditPage')
  ),
  EventRegistrationCompletePage: React.lazy(
    () => import('./staff/EventRegistrationCompletePage')
  ),
  EventRegistrationCompletedPage: React.lazy(
    () => import('./staff/EventRegistrationCompletedPage')
  ),
  EventManagerEditPage: React.lazy(
    () => import('./staff/EventManagerEditPage')
  ),
  EventAddonManagerPage: React.lazy(
    () => import('./staff/EventAddonManagerPage')
  ),
  EventAddonManagerNewPage: React.lazy(
    () => import('./staff/EventAddonManagerNewPage')
  ),
  EventAddonManagerEditPage: React.lazy(
    () => import('./staff/EventAddonManagerEditPage')
  ),
  EventRegistrationMiscellaneousNewPage: React.lazy(
    () => import('./staff/EventRegistrationMiscellaneousNewPage')
  ),
  EventRegistrationMiscellaneousEditPage: React.lazy(
    () => import('./staff/EventRegistrationMiscellaneousEditPage')
  ),
  FamilyCaregiverManagerPage: React.lazy(
    () => import('./staff/FamilyCaregiverManagerPage')
  ),
  FamilyCaregiverNewPage: React.lazy(
    () => import('./staff/FamilyCaregiverNewPage')
  ),
  FamilyCaregiverEditPage: React.lazy(
    () => import('./staff/FamilyCaregiverEditPage')
  ),
  FamilyCaregiverHistoryPage: React.lazy(
    () => import('./staff/FamilyCaregiverHistoryPage')
  ),
  FamilyChildManagerPage: React.lazy(
    () => import('./staff/FamilyChildManagerPage')
  ),
  FamilyEditAutomatedBillingType: React.lazy(
    () => import('./staff/FamilyEditAutomatedBillingTypePage')
  ),
  FamilyEditAutomatedBillingTypeCompleted: React.lazy(
    () => import('./staff/FamilyEditAutomatedBillingTypeCompletedPage')
  ),
  FamilyEditPage: React.lazy(() => import('./staff/FamilyEditPage')),
  FamilyOverviewPage: React.lazy(() => import('./staff/FamilyOverviewPage')),
  FamilyLedgerPage: React.lazy(() => import('./staff/FamilyLedgerPage')),
  FamilyLedgerReportPage: React.lazy(
    () => import('./staff/FamilyLedgerReportPage')
  ),
  FamilyLedgerOverviewPage: React.lazy(
    () => import('./staff/FamilyLedgerOverviewPage')
  ),
  FamilyMakePaymentPage: React.lazy(
    () => import('./staff/FamilyMakePaymentPage')
  ),
  FamilyMakePaymentCompletedPage: React.lazy(
    () => import('./staff/FamilyMakePaymentCompletedPage')
  ),
  FamilyNotesPage: React.lazy(() => import('./staff/FamilyNotesPage')),
  FamilyNewPage: React.lazy(() => import('./staff/FamilyNewPage')),
  FamilySignedDocumentsPage: React.lazy(
    () => import('./staff/FamilySignedDocumentsPage')
  ),
  LedgerForOrgPage: React.lazy(() => import('./staff/LedgerForOrgPage')),
  ProgramEnrollmentManagerPage: React.lazy(
    () => import('./staff/ProgramEnrollmentManagerPage')
  ),
  ProgramEnrollmentNewPage: React.lazy(
    () => import('./staff/ProgramEnrollmentNewPage')
  ),
  ProgramEnrollmentEditPage: React.lazy(
    () => import('./staff/ProgramEnrollmentEditPage')
  ),
  ProgramEnrollmentCompletePage: React.lazy(
    () => import('./staff/ProgramEnrollmentCompletePage')
  ),
  ProgramEnrollmentCompletedPage: React.lazy(
    () => import('./staff/ProgramEnrollmentCompletedPage')
  ),
  ProgramSchedulePage: React.lazy(() => import('./staff/ProgramSchedulePage')),
  ProgramEnrollmentFormReportPage: React.lazy(
    () => import('./staff/ProgramEnrollmentFormReportPage')
  ),
};

export const teacherTablet = {
  HomePage: React.lazy(() => import('./teacher-tablet/HomePage')),
};
export const kiosk = {
  KioskSetupPage: React.lazy(() => import('./kiosk/KioskSetupPage')),
  KioskFamilyPage: React.lazy(() => import('./kiosk/KioskFamilyPage')),
};

export const parentPortal = {
  FamilyDashboardPage: React.lazy(
    () => import('./parent-portal/FamilyDashboardPage')
  ),
  ParentPortalInfoOptions: React.lazy(
    () => import('./parent-portal/ParentPortalInfoOptions')
  ),
  ParentPortalChildren: React.lazy(
    () => import('./parent-portal/ParentPortalChildren')
  ),
  ParentPortalChildrenDetailsPage: React.lazy(
    () => import('./parent-portal/ParentPortalChildrenDetailsPage')
  ),
  ParentPortalCaregivers: React.lazy(
    () => import('./parent-portal/ParentPortalCaregivers')
  ),
  ParentPortalCaregiverEditPage: React.lazy(
    () => import('./parent-portal/ParentPortalCaregiverEditPage')
  ),
  ParentPortalCaregiverNewPage: React.lazy(
    () => import('./parent-portal/ParentPortalCaregiverNewPage')
  ),
  ParentPortalProgramSchedule: React.lazy(
    () => import('./parent-portal/ParentPortalProgramSchedule')
  ),
  ParentPortalClassSchedule: React.lazy(
    () => import('./parent-portal/ParentPortalClassSchedule')
  ),
  ParentPortalClassInfoPage: React.lazy(
    () => import('./parent-portal/ClassInfoPage')
  ),
  ParentPortalUpcomingEventsPage: React.lazy(
    () => import('./parent-portal/ParentPortalUpcomingEventsPage')
  ),
  ParentPortalEventRegistrationNewPage: React.lazy(
    () => import('./parent-portal/ParentPortalEventRegistrationNewPage')
  ),
  ParentPortalEventRegistrationEditPage: React.lazy(
    () => import('./parent-portal/ParentPortalEventRegistrationEditPage')
  ),
  ParentPortalEventRegistrationCompletePage: React.lazy(
    () => import('./parent-portal/ParentPortalEventRegistrationCompletePage')
  ),
  ParentPortalEventRegistrationCompletedPage: React.lazy(
    () => import('./parent-portal/ParentPortalEventRegistrationCompletedPage')
  ),
  ParentPortalEnroll: React.lazy(
    () => import('./parent-portal/ParentPortalEnroll')
  ),
  ParentPortalClassEnrollmentComplete: React.lazy(
    () => import('./parent-portal/ParentPortalClassEnrollmentCompletePage')
  ),
  ParentPortalClassEnrollmentCompleted: React.lazy(
    () => import('./parent-portal/ParentPortalClassEnrollmentCompletedPage')
  ),
  LedgerOverviewPage: React.lazy(
    () => import('./parent-portal/LedgerOverviewPage')
  ),
  LedgerDetailsPage: React.lazy(
    () => import('./parent-portal/LedgerDetailsPage')
  ),
  LedgerReportPage: React.lazy(
    () => import('./parent-portal/FamilyLedgerReportPage')
  ),
  FamilyMakePaymentPage: React.lazy(
    () => import('./parent-portal/FamilyMakePaymentPage')
  ),
};
