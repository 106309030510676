interface QueryParam {
  [key: string]: string | number | null | undefined;
}

/* Helper function to create the qs dynamically */
export function buildQueryString(params: QueryParam[]): string {
  const queryParams = params
    .map((param) => {
      const key = Object.keys(param)[0];
      const value = param[key];
      if (value !== undefined && value !== null) {
        return `${key}=${String(value)}`;
      }
      return null;
    })
    .filter((param) => param !== null) as string[];

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
}
